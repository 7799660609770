<template>
  <v-icon
    v-if="processed"
    color="teal"
    :large="large"
  >
    mdi-check-bold
  </v-icon>
  <v-icon
    v-else
    color="error"
    :large="large"
  >
    mdi-alert-circle-outline
  </v-icon>
</template>

<script>

export default {
  props: {
    processed: Boolean,
    large: Boolean,
  },
}

</script>
