export const GetWebhookEventQuery = `
  query GetWebhookEvent($id: WebhookEventId!)  {
    webhookEvent (id: $id) {
      id
      name
      createdAt
      lastAttemptedAt
      processedAt
      payload
        logs {
          page(page: 1) {
            id
            code
            createdAt
            status
            body
          }
        }
    }
  }
`

export const GetWebhookEventsQuery = `
  query GetWebhookEvents($page: Int!, $filters: WebhookEventFiltersInput) {
    webhookEvents(limit: 100, filters: $filters) {
      totalNumberOfPages
      page(page: $page) {
        id
        name
        createdAt
        lastAttemptedAt
        processedAt
      }
    }
  }
`

export const RetryWebhookEventMutation = `
  mutation retryWebhookEvents($id: WebhookEventId!){
    retryWebhookEvent(webhookEventId: $id) {
      id
      name
      createdAt
      lastAttemptedAt
      processedAt
    }
  }
`
