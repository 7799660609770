import { formatDatetime, shortUuid } from '../../utils'
import { GetWebhookEventQuery, GetWebhookEventsQuery, RetryWebhookEventMutation } from '../../graphql/webhookEvents'
import {
  WebhookEventAction,
  WebhookEventGetter,
  WebhookEventMutation,
} from "../types"
import http from "../../http"

const Status = {
  Loading: "loading",
  Failed: "failed",
  Succeed: "succeed",
}

const resultsPerPage = 30

export default {
  state: {
    webhookEvents: [],
    webhookEvent: null,
    status: null,
    errors: {},
    page: 1,
    totalPages: null,
    filters: {},
  },
  mutations: {
    [WebhookEventMutation.GetAllSucceed]: (state, payload) => {
      state.webhookEvents = payload
      state.status = Status.Succeed
    },
    [WebhookEventMutation.GetOneSucceed]: (state, payload) => {
      state.webhookEvent = payload
      state.status = Status.Succeed
    },
    [WebhookEventMutation.Errors]: (state, error) => {
      state.errors = error
      state.status = Status.Default
    },
    [WebhookEventMutation.RetrySucceed]: (state) => state.status = Status.Succeed,
    [WebhookEventMutation.Failed]: (state) => state.status = Status.Failed,
    [WebhookEventMutation.Loading]: (state) => state.status = Status.Loading,
    [WebhookEventMutation.Page]: (state, payload) => state.page = payload,
    [WebhookEventMutation.TotalPages]: (state, payload) => state.totalPages = payload,
    [WebhookEventMutation.ApplyFilters]: (state, payload) => state.filters = payload,
  },
  actions: {
    [WebhookEventAction.GetAll]: async ({ state, commit }, page) => {
      commit(WebhookEventMutation.Loading)

      page = page || state.page || 1
      http.post('/graphql', JSON.stringify({
        query: GetWebhookEventsQuery,
        variables: {
          page: page,
          limit: resultsPerPage,
          filters: state.filters,
        },
      }))
        .then((res) => {
          commit(WebhookEventMutation.Page, page)
          commit(WebhookEventMutation.GetAllSucceed, res.data.data.webhookEvents.page)
          commit(WebhookEventMutation.TotalPages, res.data.data.webhookEvents.totalNumberOfPages)
        })
        .catch(() => commit(WebhookEventMutation.Failed))
    },
    [WebhookEventAction.GetOne]: async ({ commit }, payload) => {
      commit(WebhookEventMutation.Loading)

      http.post('/graphql', JSON.stringify({
        query: GetWebhookEventQuery,
        variables: { id: payload },
      }))
        .then((res) => commit(WebhookEventMutation.GetOneSucceed, res.data.data.webhookEvent))
        .catch(() => commit(WebhookEventMutation.Failed))
    },
    [WebhookEventAction.RetryOne]: async ({ commit }, id) => {
      commit(WebhookEventMutation.Loading)

      http.post('/graphql', JSON.stringify({
        query: RetryWebhookEventMutation,
        variables: { id: id },
      }))
        .then(() => commit(WebhookEventMutation.RetrySucceed))
        .catch(() => commit(WebhookEventMutation.Failed))
    },
    [WebhookEventAction.ApplyFilters]: async ({ commit, dispatch }, payload) => {
      commit(WebhookEventMutation.ApplyFilters, payload)
      commit(WebhookEventMutation.Page, 1)
      dispatch(WebhookEventAction.GetAll)
    },
  },
  getters: {
    [WebhookEventGetter.List]: (state) => {
      return state.webhookEvents.map((webhookEvent) => {
        return {
          ...webhookEvent,
          shortId: shortUuid(webhookEvent.id),
          createdAt: formatDatetime(webhookEvent.createdAt, false),
          processedAt: formatDatetime(webhookEvent.processedAt, false),
          lastAttemptedAt: formatDatetime(webhookEvent.lastAttemptedAt, false),
        }
      })
    },
    [WebhookEventGetter.Details]: (state) => {
      if (!state.webhookEvent) return null

      return {
        ...state.webhookEvent,
        shortId: shortUuid(state.webhookEvent.id),
        createdAt: formatDatetime(state.webhookEvent.createdAt, false),
        processedAt: formatDatetime(state.webhookEvent.processedAt, false),
        lastAttemptedAt: formatDatetime(state.webhookEvent.lastAttemptedAt, false),
      }
    },

    [WebhookEventGetter.IsLoading]: (state) => state.status === Status.Loading,
    [WebhookEventGetter.HasError]: (state) => state.status === Status.Failed,
    [WebhookEventGetter.Page]: (state) => state.page,
    [WebhookEventGetter.TotalPages]: (state) => state.totalPages,
    [WebhookEventGetter.Filters]: (state) => state.filters,
  },
}
