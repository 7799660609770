<template>
  <Page :loading="loading" :error="error" type="list">
    <template v-slot:title>Webhook events</template>
    <template v-slot:no-reload>
      <v-form>
        <v-row>
          <v-col cols="12" md="3">
            <v-select
              :items="values.name"
              v-model="filters.name"
              label="Event"
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              :items="values.processed"
              item-value="value"
              item-text="label"
              v-model="filters.processed"
              label="Traité"
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" align="right">
            <v-btn
                fab
                color="primary"
                @click="refresh"
                size="small">
              <v-icon dark>
                mdi-refresh
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-if="webhookEvents.length">
        <v-simple-table>
          <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Création</th>
            <th>Dernière tentative</th>
            <th>Traitement</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="webhookEvent in webhookEvents"
              :key="webhookEvent.id"
              tabIndex="0"
              @keypress.enter="() => onWebhookEventClick(webhookEvent.id)"
          >
            <td>
              <router-link :to="{ name: 'webhookEventsDetails', params: { id: webhookEvent.id } }">
                #{{ webhookEvent.shortId }}
              </router-link>
            </td>
            <td>{{ webhookEvent.name }}</td>
            <td>{{ webhookEvent.createdAt }}</td>
            <td>{{ webhookEvent.lastAttemptedAt }}</td>
            <td>{{ webhookEvent.processedAt }}</td>
            <td class="text-right">
              <StatusIcon :processed="webhookEvent.processedAt !== ' - '" />
              <v-btn
                :disabled="webhookEvent.processedAt !== ' - '"
                class="ml-2"
                icon
                @click="retry(webhookEvent.id)"
              >
               <v-icon>mdi-reload</v-icon>
              </v-btn>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
        <div class="text-center  mt-8" v-if="nbPages">
          <v-pagination
              v-model="page"
              :length="nbPages"
              :total-visible="8"
              rounded="circle"
          ></v-pagination>
        </div>
      </template>
  </Page>
</template>

<script>
import { WebhookEventAction, WebhookEventGetter } from "../../store/types"
import { mapGetters } from 'vuex'
import Page from "../Layout/Page"
import StatusIcon from "./StatusIcon.vue"

export default {
  components: {
    StatusIcon,
    Page,
  },
  data () {
    return {
      values: {
        name: [
          'ORDER_COMPLETED',
          'ORDER_REFUNDED',
          'PRICE_OFFER_CREATED',
          'DROP_ARCHIVED',
        ],
        processed: [
          { label: 'Oui', value: true },
          { label: 'Non', value: false },
        ],
      },
      filters: this.$store.getters[WebhookEventGetter.Filters],
    }
  },
  mounted() {
    this.$store.dispatch(WebhookEventAction.GetAll)
  },

  computed: {
    ...mapGetters({
      webhookEvents: WebhookEventGetter.List,
      loading: WebhookEventGetter.IsLoading,
      error: WebhookEventGetter.HasError,
      nbPages: WebhookEventGetter.TotalPages,
    }),
    page: {
      get() { return this.$store.getters[WebhookEventGetter.Page] },
      set(value) { this.$store.dispatch(WebhookEventAction.GetAll, value) },
    },
  },
  methods: {
    onWebhookEventClick(id) {
      this.$router.push({ name: "webhookEventsDetails", params: { id } })
    },
    retry(id) {
      this.$store.dispatch(WebhookEventAction.RetryOne, id)
    },
    refresh() {
      this.$store.dispatch(WebhookEventAction.GetAll)
    },
  },
  watch: {
    "filters.name"() {
      this.$store.dispatch(WebhookEventAction.ApplyFilters, this.filters)
    },
    "filters.processed"() {
      this.$store.dispatch(WebhookEventAction.ApplyFilters, this.filters)
    },
  },
}
</script>
