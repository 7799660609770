<template>
  <Page :loading="loading" :error="error">
    <template v-if="webhookEvent" v-slot:title>
      <v-card outlined elevation="2">
        <v-card-title class="grey lighten-4">
          <h2><v-icon large>mdi-webhook</v-icon> WebhookEvent : {{ webhookEvent.id }}</h2>
          <v-spacer />
          <StatusIcon :processed="webhookEvent.processedAt !== ' - '" large/>
        </v-card-title>
      </v-card>
    </template>
    <template v-if="webhookEvent">
      <v-row>
        <v-col cols="12" md="2">
          <v-card height="100%">
            <v-card-title>
              {{ webhookEvent.name }}
            </v-card-title>
            <v-card-text>
              <v-simple-table>
                <tbody>
                  <tr>
                    <td class="font-weight-medium">Création</td>
                    <td class="font-weight-light">{{ webhookEvent.createdAt }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-medium">Dernière tentative</td>
                    <td class="font-weight-light">{{ webhookEvent.lastAttemptedAt }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-medium">Traitement</td>
                    <td class="font-weight-light">{{ webhookEvent.processedAt }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="5">
          <v-card height="100%">
            <v-card-title>Payload</v-card-title>
            <v-card-text>
              <v-sheet
                class="pa-6"
                elevation="2"
              >
                <pre>{{ jsonPretty(webhookEvent.payload) }}</pre>
              </v-sheet>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="5">
          <v-card height="100%">
            <v-card-title>Logs</v-card-title>
            <v-card-text>
              <v-expansion-panels
                v-model="panel"
                multiple
              >
                <v-expansion-panel
                  v-for="(item, i) in webhookEvent.logs.page"
                  :key="i"
                >
                  <v-expansion-panel-header
                    disable-icon-rotate
                  >
                    <template v-slot:actions>
                      <StatusIcon :processed="item.status === 'PROCESSED'" />
                    </template>
                    {{ item.createdAt }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <pre>{{ jsonPretty(item.body) }}</pre>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </Page>
</template>

<script>

import { formatFullName, jsonPretty } from "../../utils"
import { WebhookEventAction, WebhookEventGetter } from "../../store/types"
import { mapGetters } from 'vuex'
import Page from "../Layout/Page"
import StatusIcon from "./StatusIcon.vue"

export default {
  components: {
    StatusIcon,
    Page,
  },

  data() {
    return {
      panel: [0],
    }
  },

  mounted() {
    this.$store.dispatch(WebhookEventAction.GetOne, this.$route.params.id)
  },

  computed: {
    ...mapGetters({
      loading: WebhookEventGetter.IsLoading,
      error: WebhookEventGetter.HasError,
      webhookEvent: WebhookEventGetter.Details,
    }),
  },

  methods: {
    jsonPretty,
    formatFullName,
  },
}
</script>
