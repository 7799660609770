import authModule from "./modules/auth"
import couponModule from "./modules/coupon"
import customerModule from "./modules/customer"
import dashboardModule from "./modules/dashboard"
import giftCardModule from "./modules/giftCard"
import orderModule from "./modules/order"
import { RootAction } from "./types"
import Vue from "vue"
import Vuex from "vuex"
import webhookEventModule from "./modules/webhookEvent"

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth: {
      namespaced: false,
      ...authModule,
    },
    customer: {
      namespaced: false,
      ...customerModule,
    },
    coupon: {
      namespaced: false,
      ...couponModule,
    },
    giftCard: {
      namespaced: false,
      ...giftCardModule,
    },
    order: {
      namespaced: false,
      ...orderModule,
    },
    webhookEvent: {
      namespaced: false,
      ...webhookEventModule,
    },
    dashboard: {
      namespaced: false,
      ...dashboardModule,
    },
  },
  state: {
    authJwt: null,
  },
  actions: {
    [RootAction.Init]: () => {
    },
  },
})

export default store
